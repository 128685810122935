import React, { useEffect, useState, useMemo, useCallback } from 'react';
import {
  BigidIconSize,
  BigidContentItem,
  entityEventsEmitter,
  EntityEvents,
  ToolbarAction,
  BigidToolbar,
  ToolbarProps,
  ColumnInfo,
  BigidSidePanel,
  BigidPaper,
} from '@bigid-ui/components';
import { BigidGridColumnTypes, BigidGridRow, BigidGridColumn, BigidGrid, BigidGridProps } from '@bigid-ui/grid';
import makeStyles from '@mui/styles/makeStyles';

import { BigidArchiveIcon } from '@bigid-ui/icons';
import {
  DataCatalogObjectDetails,
  ObjectAttributeDetails,
} from '../../../../../../DataCatalog/DataCatalogDetails/DataCatalogDetailsService';
import { DataExplorerColumnsProps } from '../DataExplorerColumns/DataExplorerColumns';
import { useLocalTranslation } from '../../../../../translations';
import { getIsClassifyFileNamesEnabled } from '../../../../../../DataCatalog/utils';
import { AssociatedColumn, DataCatalogAttribute } from '../../../../../../DataCatalog/DataCatalogAttributes';
import { AttributesGridColumn } from '../../utils';
import {
  DataCatalogObjectType,
  DataCatalogRecordScannerTypeGroup,
} from '../../../../../../DataCatalog/DataCatalogService';
import {
  AttributeMappingDialog,
  AttributeMappingDialogProps,
} from '../DataExplorerColumns/modalDialogEditors/AttributeMappingDialog';
import { getApplicationPreference } from '../../../../../../../services/appPreferencesService';
import { isPermitted } from '../../../../../../../services/userPermissionsService';
import { CATALOG_PERMISSIONS } from '@bigid/permissions';
import { analyticsService } from '../../../../../../../services/analyticsService';
import { DataExplorerEventsEnum, getBiEventName } from '../../../../../events';
import { AttributesDeleteConfirmationDialogProps } from '../../../../../../DataCatalog/DataCatalogAttributes/AttributesDeleteConfirmationDialog';
import {
  getChipTitleAndLabel,
  getConfidenceLevelColumnTitle,
  getConfidenceLevelIndicator,
  isAttributeExpired,
} from '../../../../../../DataCatalog/DataCatalogAttributes/utils';
import { ConfidenceLevelExplanation } from '../../../../../../../components/ConfidenceLevelExplanation/ConfidenceLevelExplanation';
import { deleteManualFields, ManualFieldType } from '../../../../../../DataCatalog/DataCatalogColumns';
import { notificationService } from '../../../../../../../services/notificationService';
import { AttributesDeleteConfirmationDialog } from './AttributesDeleteConfirmationDialog';
import { defaultObjectDetails, useGetObjectDetails } from '../hooks/useGetObjectDetails';
import { useQuery } from 'react-query';
import { fetchAttributesData } from './DataExplorerAttributesService';
import { noop } from 'lodash';
import { AttributesMasterDetailsPreview } from './AttributesMasterDetailsPreview';
import { COLUMNS_COLUMN_NAME, CONFIDENCE_LEVEL_COLUMN_NAME } from './constants';
import { getDisplayGridColumns } from './utils';
import { AttributeClearValue } from './AttributeClearValue';
import { Box } from '@mui/material';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
  },
});

const excludedAttributeTypes = ['ClassificationMd', 'Classification', 'Manual', 'Enrichment Attribute'];

function getNumberOfFindings(attributesDetails: ObjectAttributeDetails[], attributeName: string): number {
  let numberOfFindings;
  const attributeDetails = attributesDetails?.find(({ name }) => name === attributeName);

  if (attributeDetails) {
    numberOfFindings = attributeDetails.count;
  }

  return numberOfFindings;
}

export const DataExplorerAttributes = ({
  datasource,
  id: fullyQualifiedName,
  isExtendedView,
  detailedObjectType,
}: DataExplorerColumnsProps) => {
  const { scannerType, value: source } = datasource;

  const { data: objectDetailsData } = useGetObjectDetails(fullyQualifiedName);
  const {
    data: { type: objectType, scanner_type_group },
  } = objectDetailsData;

  const classes = useStyles({});
  const { t } = useLocalTranslation('DataExplorerAttributes');
  const isClassifyFileNamesEnabled = getIsClassifyFileNamesEnabled();

  const [selectedAttributeItem, setSelectedAttributeItem] = useState<BigidContentItem>(null);
  const [isClearValueWidgetEnabled, setIsClearValueWidgetEnabled] = useState<boolean>(false);
  const [onlyOneCheckboxIsSelected, setOnlyOneCheckboxIsSelected] = useState<boolean>(false);
  const isUnstructured =
    scanner_type_group === DataCatalogRecordScannerTypeGroup.UNSTRUCTURED ||
    scanner_type_group === DataCatalogRecordScannerTypeGroup.EMAIL;

  const [attributeMappingDialogState, setAttributeMappingDialogState] = useState<AttributeMappingDialogProps>({
    fullyQualifiedName,
    columnName: undefined,
    isOpen: false,
    dialogTitle: 'Add Attribute',
    scannerType: '',
  });

  const [deleteConfirmationDialogState, setDeleteConfirmationDialogState] =
    useState<AttributesDeleteConfirmationDialogProps>({
      isOpen: false,
    });

  const defaultHiddenColumns = isExtendedView ? [] : ['cachedValues', 'description', 'purposes', 'categories'];
  const [hideColumns, setHideColumns] = useState<string[]>(defaultHiddenColumns);

  const { confidenceLevelExplainTooltipEnabled, clusteringEnabled, isDataPreviewDisabled } = useMemo(
    () => ({
      confidenceLevelExplainTooltipEnabled: getApplicationPreference('CONFIDENCE_LEVEL_EXPLAIN_TOOLTIP_ENABLED'),
      clusteringEnabled: getApplicationPreference('CLUSTERING_ENABLED'),
      isDataPreviewDisabled: getApplicationPreference('DATA_PREVIEW_DISABLED'),
    }),
    [],
  );

  const {
    isFetchClearAttrValueAvailable,
    isDataCatalogAddUnstructuredAttributeEnabled,
    isAddAttributeEnabled,
    displayConfidenceLevelColumn,
  } = useMemo(() => {
    const isDataCatalogAddUnstructuredAttributeEnabled = Boolean(
      getApplicationPreference('DATA_CATALOG_ADD_UNSTRUCTURED_ATTRIBUTE'),
    );
    const displayConfidenceLevelColumn = Boolean(getApplicationPreference('ATTR_CONFIDENCE_LEVEL_COLUMN_ENABLED'));

    return {
      isDataCatalogAddUnstructuredAttributeEnabled,
      isFetchClearAttrValueAvailable:
        Boolean(getApplicationPreference('FETCH_CLEAR_ATTR_VALUE_ENABLED')) &&
        isPermitted(CATALOG_PERMISSIONS.INVESTIGATE.name) &&
        isPermitted(CATALOG_PERMISSIONS.PREVIEW_FILE_INVESTIGATION.name),
      isAddAttributeEnabled: isUnstructured && isDataCatalogAddUnstructuredAttributeEnabled,
      displayConfidenceLevelColumn,
    };
  }, [isUnstructured]);

  const { data, isFetching, refetch } = useQuery(
    ['fetchAttributes', fullyQualifiedName],
    async () => fetchAttributesData(fullyQualifiedName),
    {
      placeholderData: {
        data: [],
        objectDetails: defaultObjectDetails,
        totalCount: 0,
      },
    },
  );

  const closeEditAttributeMappingDialog = useCallback(() => {
    setAttributeMappingDialogState(prevState => ({
      ...prevState,
      isOpen: false,
    }));
  }, []);

  const closeDeleteConfirmationDialog = useCallback(() => {
    setDeleteConfirmationDialogState({
      isOpen: false,
    });
  }, []);

  useEffect(() => {
    setAttributeMappingDialogState(prevState => ({
      ...prevState,
      fullyQualifiedName,
    }));
  }, [fullyQualifiedName]);

  useEffect(() => {
    const trackData = {
      fullyQualifiedName,
      scannerType: scanner_type_group,
      dsType: scannerType,
      dsName: source,
    };

    analyticsService.trackManualEvent(getBiEventName(DataExplorerEventsEnum.ATTRIBUTE_TAB), trackData);
  }, [fullyQualifiedName, scanner_type_group, scannerType, source]);

  useEffect(() => {
    if (!isUnstructured || !isDataCatalogAddUnstructuredAttributeEnabled) {
      setOnlyOneCheckboxIsSelected(true);
    }
  }, [isDataCatalogAddUnstructuredAttributeEnabled, isUnstructured]);

  const attributeMappingDialogConfig: AttributeMappingDialogProps = useMemo(
    () => ({
      ...attributeMappingDialogState,
      onClose: closeEditAttributeMappingDialog,
    }),
    [attributeMappingDialogState, closeEditAttributeMappingDialog],
  );

  const deleteConfirmationDialogConfig: AttributesDeleteConfirmationDialogProps = useMemo(
    () => ({
      ...deleteConfirmationDialogState,
      onClose: closeDeleteConfirmationDialog,
    }),
    [closeDeleteConfirmationDialog, deleteConfirmationDialogState],
  );

  const getGridData = useCallback(
    (data: DataCatalogAttribute[], objectDetails: DataCatalogObjectDetails): AttributesGridColumn[] => {
      return data.map((att: DataCatalogAttribute) => {
        const {
          attribute_id,
          attribute_original_name,
          attribute_type,
          attribute_original_type,
          attribute_name,
          column_list = [],
          categories = [],
          description,
          business_flow = [],
          is_support_investigation = false,
          investigation_scan_id_list = [],
        } = att;
        const { attribute_details } = objectDetails;

        const colsToDisplay = column_list
          .reduce((aggCol, col: AssociatedColumn) => {
            const { rank, calc_confidence_level, column_name } = col;
            const confidenceLevel = calc_confidence_level
              ? `(${rank}  ${(calc_confidence_level * 100).toFixed(0)}%)`
              : `(${rank})`;

            return aggCol.length > 0
              ? `${aggCol} ${column_name} ${confidenceLevel}, `
              : `${column_name} ${confidenceLevel}, `;
          }, '')
          .replace(/,\s*$/, '');

        //TODO: make generic, regardless of the detailedObjectType
        return {
          id: attribute_id,
          attribute_name,
          attribute_original_name,
          attribute_type,
          attribute_original_type,
          columns: colsToDisplay,
          categories,
          description,
          purposes: business_flow.join(', '),
          is_support_investigation,
          investigation_scan_id_list,
          confidenceLevel: {
            chips: {
              value: column_list.reduce((chips, column) => {
                const shouldDisplayTooltip = confidenceLevelExplainTooltipEnabled && clusteringEnabled;
                const { column_name, calc_confidence_level, rank } = column;
                const { label, title } = getChipTitleAndLabel(
                  isClassifyFileNamesEnabled,
                  scanner_type_group,
                  detailedObjectType,
                  column,
                );
                const icon = getConfidenceLevelIndicator(isClassifyFileNamesEnabled, rank, detailedObjectType);

                /**
                  NOTE: a patch to fix BDT-89780, requires better solution
                  Potential solution will be to divide the logic per column:
                  i.e. Conf Level separately and shown conditionally, same for Field Names
                 */
                if (label) {
                  return [
                    ...chips,
                    {
                      id: attribute_id,
                      label,
                      icon,
                      title,
                      ...(shouldDisplayTooltip && {
                        tooltipProps:
                          !excludedAttributeTypes.includes(attribute_type) && calc_confidence_level !== undefined
                            ? {
                                width: '400px',
                                title: (
                                  <ConfidenceLevelExplanation
                                    item={{
                                      fullyQualifiedName,
                                      fieldName: column_name,
                                      attribute_type,
                                      attribute_name,
                                      confidence_level: calc_confidence_level,
                                    }}
                                  />
                                ),
                              }
                            : undefined,
                      }),
                    },
                  ];
                } else {
                  return chips;
                }
              }, []),
              isDisabled: true,
            },
          },
          cachedValues:
            investigation_scan_id_list.length > 0
              ? {
                  icon: {
                    icon: BigidArchiveIcon,
                    size: BigidIconSize.REGULAR,
                  },
                }
              : undefined,
          numberOfFindings: getNumberOfFindings(attribute_details, attribute_original_name),
        };
      });
    },
    [
      confidenceLevelExplainTooltipEnabled,
      clusteringEnabled,
      fullyQualifiedName,
      detailedObjectType,
      isClassifyFileNamesEnabled,
      scanner_type_group,
    ],
  );

  const gridColumns = useMemo((): BigidGridColumn<AttributesGridColumn>[] => {
    return [
      {
        name: 'attribute_name',
        title: 'Attribute',
        getCellValue: ({ attribute_name }) => attribute_name,
        type: BigidGridColumnTypes.TEXT,
        isListColumn: true,
        sortingEnabled: false,
      },
      {
        name: 'numberOfFindings',
        title: '# of Findings',
        getCellValue: ({ numberOfFindings }) => numberOfFindings,
        type: BigidGridColumnTypes.NUMBER,
        sortingEnabled: false,
      },
      {
        name: CONFIDENCE_LEVEL_COLUMN_NAME,
        title: getConfidenceLevelColumnTitle(isClassifyFileNamesEnabled, scanner_type_group, detailedObjectType),
        getCellValue: ({ confidenceLevel }) => confidenceLevel,
        type: BigidGridColumnTypes.CHIPS,
        width: 350,
        sortingEnabled: false,
      },
      {
        name: COLUMNS_COLUMN_NAME,
        title: 'Column names',
        getCellValue: ({ columns }) => columns,
        type: BigidGridColumnTypes.TEXT,
        isListColumn: true,
        sortingEnabled: false,
      },
      {
        name: 'categories',
        title: 'Categories',
        getCellValue: ({ categories }) => ({
          categories: {
            value: categories?.map(({ display_name, color }) => ({
              categoryName: display_name,
              categoryColor: color,
            })),
          },
        }),
        type: BigidGridColumnTypes.CATEGORIES,
        isListColumn: true,
        sortingEnabled: false,
      },
      {
        name: 'purposes',
        title: 'Purposes',
        getCellValue: ({ purposes }) => purposes,
        type: BigidGridColumnTypes.TEXT,
        isListColumn: true,
        sortingEnabled: false,
      },
      {
        name: 'description',
        title: 'Description',
        getCellValue: ({ description }) => description,
        type: BigidGridColumnTypes.TEXT,
        isListColumn: true,
        sortingEnabled: false,
      },
      {
        name: 'cachedValues',
        title: 'Cached value',
        getCellValue: ({ cachedValues }) => cachedValues,
        type: BigidGridColumnTypes.ICON,
        isListColumn: true,
        isHiddenByDefault: !isFetchClearAttrValueAvailable,
        sortingEnabled: false,
      },
    ];
  }, [isClassifyFileNamesEnabled, scanner_type_group, detailedObjectType, isFetchClearAttrValueAvailable]);

  useEffect(() => {
    setIsClearValueWidgetEnabled(false);
    setSelectedAttributeItem(null);
  }, [fullyQualifiedName]);

  const displayGridColumn = useMemo(() => {
    let filteredGridColumn = gridColumns;
    const displayConfidenceLevelColumn = getApplicationPreference('ATTR_CONFIDENCE_LEVEL_COLUMN_ENABLED');
    if (isUnstructured || displayConfidenceLevelColumn) {
      filteredGridColumn = filteredGridColumn.filter(({ name }) => name !== COLUMNS_COLUMN_NAME);
    }

    if (!displayConfidenceLevelColumn) {
      filteredGridColumn = filteredGridColumn.filter(({ name }) => name !== CONFIDENCE_LEVEL_COLUMN_NAME);
    }
    return filteredGridColumn;
  }, [gridColumns, isUnstructured]);

  const handleGridRowClick = (row: BigidGridRow): void => {
    setSelectedAttributeItem(row as BigidContentItem);
    setIsClearValueWidgetEnabled(!!row && isFetchClearAttrValueAvailable);
  };

  const handleClearValueFetched = (): void => {
    entityEventsEmitter.emit(EntityEvents.RELOAD);
  };

  const handleClearValueWidgetClose = (): void => {
    setIsClearValueWidgetEnabled(false);
    setSelectedAttributeItem(null);
  };

  const isAttributeButtonsDisplayable = useCallback(() => {
    return objectType !== DataCatalogObjectType.MODEL;
  }, [objectType]);

  const actions: ToolbarAction[] = [];

  if (isAddAttributeEnabled) {
    const attributeActions: ToolbarAction[] = [
      {
        label: t('actions.addAttribute.label'),
        isGlobal: true,
        execute: async () => {
          return new Promise((resolve, reject) => {
            setAttributeMappingDialogState(prevState => {
              return {
                ...prevState,
                isOpen: true,
                onSubmit: () => {
                  closeEditAttributeMappingDialog();
                  resolve({ shouldGridReload: true, shouldClearSelection: true });
                  refetch();
                },
                onClose: () => {
                  reject();
                },
              };
            });
          });
        },
        show: () =>
          isPermitted(CATALOG_PERMISSIONS.EDIT_MANUAL_FIELDS.name) &&
          isPermitted(CATALOG_PERMISSIONS.READ_MANUAL_FIELDS.name) &&
          isAttributeButtonsDisplayable(),
      },
      {
        label: t('actions.deleteAttribute.label'),
        isGlobal: false,
        execute: async params => {
          return new Promise((resolve, reject) => {
            setDeleteConfirmationDialogState({
              isOpen: true,
              onSubmit: () => {
                try {
                  closeDeleteConfirmationDialog();
                  const data = params.selectedRows.map(
                    ({ attribute_original_name, attribute_type, attribute_original_type }) => ({
                      fullyQualifiedName,
                      value: attribute_original_name,
                      attribute_type: attribute_original_type ?? attribute_type,
                      type: ManualFieldType.ATTRIBUTE,
                    }),
                  );

                  deleteManualFields(data);
                } catch ({ message }) {
                  console.error(`An error has occurred: ${message}`);
                  notificationService.error('An error has occurred');
                } finally {
                  resolve({ shouldGridReload: true, shouldClearSelection: true });
                }
              },
              onClose: () => {
                reject();
              },
            });
          });
        },
        show: params => isAttributeButtonsDisplayable() && params.selectedRowIds.length > 0,
      },
    ];
    actions.push(...attributeActions);
  }

  const columnsToDisplay = getDisplayGridColumns(gridColumns, isUnstructured, displayConfidenceLevelColumn);
  const isListMode = !!selectedAttributeItem && !isDataPreviewDisabled && !isExtendedView;

  const gridConfig: BigidGridProps<AttributesGridColumn> = {
    columns: columnsToDisplay,
    showSelectionColumn: isAddAttributeEnabled,
    totalRowsCount: data.totalCount,
    loading: isFetching,
    listMode: isListMode,
    selectedRowIds: selectedAttributeItem ? [selectedAttributeItem.id] : [],
    rows: getGridData(data.data, data.objectDetails),
    onRowClick: isFetchClearAttrValueAvailable && handleGridRowClick,
    hiddenColumnNames: hideColumns,
    onPagingChanged: noop,
  };

  const handleHideColumns = (columns: ColumnInfo[]) => {
    setHideColumns(columns.filter(column => !column.checked).map(column => column.name));
  };

  const columnsList = displayGridColumn.reduce<ColumnInfo[]>((acc, column) => {
    const columnsToExcludeFromChooser = ['attribute_name'];
    if (!columnsToExcludeFromChooser.includes(column.name)) {
      return [
        ...acc,
        {
          checked: !hideColumns.includes(column.name),
          name: column.name,
          label: column.title,
          title: column.title,
        },
      ];
    }
    return acc;
  }, []);

  const toolbarConfig: ToolbarProps = {
    totalRows: data.totalCount,
    isFetchingCount: isFetching,
    columns: columnsList,
    onColumnChange: handleHideColumns,
    shouldShowColumnChooser: !isExtendedView,
    entityName: 'attributes',
    toolbarActions: actions,
  };

  const shouldShowClearValueWidget =
    !isDataPreviewDisabled &&
    isClearValueWidgetEnabled &&
    onlyOneCheckboxIsSelected &&
    isAttributeButtonsDisplayable() &&
    !isExtendedView;

  return (
    <div className={classes.root}>
      {!isExtendedView && <BigidToolbar {...toolbarConfig} />}

      <BigidPaper>
        <BigidGrid key={fullyQualifiedName} {...gridConfig}>
          {shouldShowClearValueWidget && (
            <AttributesMasterDetailsPreview
              dataAid="AttributesMasterDetailsPreview"
              fullyQualifiedName={fullyQualifiedName}
              isExpired={isAttributeExpired(data.data, selectedAttributeItem)}
              selectedItem={selectedAttributeItem}
              onClose={handleClearValueWidgetClose}
              onFetched={handleClearValueFetched}
            />
          )}
        </BigidGrid>
      </BigidPaper>
      <AttributeMappingDialog {...attributeMappingDialogConfig} />
      <AttributesDeleteConfirmationDialog {...deleteConfirmationDialogConfig} />

      {selectedAttributeItem && isExtendedView && (
        <BigidSidePanel
          content={
            <Box padding="12px">
              <AttributeClearValue
                dataAid="AttributesMasterDetailsPreview"
                fullyQualifiedName={fullyQualifiedName}
                isExpired={isAttributeExpired(data.data, selectedAttributeItem)}
                selectedItem={selectedAttributeItem}
                onFetched={handleClearValueFetched}
              />
            </Box>
          }
          title={selectedAttributeItem.attribute_name}
          open={selectedAttributeItem && isExtendedView}
          onClose={handleClearValueWidgetClose}
          maxWidth="medium"
          isShowBackdrop
        />
      )}
    </div>
  );
};
