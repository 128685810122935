import { dateTimeService, DateTimeTypes } from '@bigid-ui/i18n';
import {
  BigidAttributeIcon,
  BigidDataSourceOnlyIcon,
  BigidFileIcon,
  BigidPolicyIcon,
  BigidTableColumnsIcon,
  BigidTermIcon,
  DataSourceIconByDsType,
} from '@bigid-ui/icons';
import React, { ReactNode } from 'react';
import { CONFIG } from '../../../config/common';
import { $state } from '../../services/angularServices';
import { httpService } from '../../services/httpService';
import { getIconByObjectType, scannerTypeGroupToIconMap } from '../DataCatalog/utils';
import { DataSourceModel, DataSourcesResponse } from '../DataSources/DataSourceConnections/DataSourceConnectionTypes';
import { FoundItemTypes, ListEntityItem } from './types';
import { queryService } from '../../services/queryService';
import {
  sendDataExplorerLandingPageViewedObjectLinkBiAnalytics,
  sendDataExplorerLandingPageDiscoveredDataSourceLinkBiAnalytics,
  sendDataExplorerLandingPageFollowedObjectLinkBiAnalytics,
} from './services/analytics';
import { FollowedObjectEntity } from '../DataCatalog/DataCatalogFollowedObjects/FollowedObjectTypes';
import { mapFieldValueToBiqlString } from '../../services/filtersToBiqlService';
import { RecentlyViewedCatalogItem } from '../DataCatalog/recentlyViewedCatalogItemService';

export const entityTypeToIconMap: Record<FoundItemTypes, ReactNode> = {
  file: <BigidFileIcon />,
  table: <BigidTableColumnsIcon />,
  policy: <BigidPolicyIcon />,
  attribute: <BigidAttributeIcon />,
  business_term: <BigidTermIcon />,
  data_source: <BigidDataSourceOnlyIcon />,
};

export const formatDate = (date: DateTimeTypes) => dateTimeService.formatDate(date, { formatString: 'dd MMM yyyy' });

export const mapDataSourcesToDataExplorerItems = (dataSources: DataSourceModel[]): ListEntityItem[] => {
  return dataSources.map(({ name, id, type, last_scan_at }) => ({
    name,
    key: String(id),
    additionalInfo: formatDate(last_scan_at),
    icon: <DataSourceIconByDsType dataSourceType={type} width={24} />,
    onClick: () => {
      const filter = mapFieldValueToBiqlString('system', String(id));
      $state.go(CONFIG.states.CATALOG, { filter }, { notify: false });
      sendDataExplorerLandingPageDiscoveredDataSourceLinkBiAnalytics();
    },
  }));
};

export const mapRecentlyViewedToListItem = (recentlyViewed: RecentlyViewedCatalogItem[]): ListEntityItem[] => {
  return recentlyViewed.map(({ objectName, fullyQualifiedName, objectType, lastViewedDate }) => {
    const Icon = getIconByObjectType(objectType);
    const filter = mapFieldValueToBiqlString('fullyQualifiedName', fullyQualifiedName);

    return {
      name: objectName,
      tooltipText: fullyQualifiedName,
      icon: <Icon />,
      key: fullyQualifiedName,
      additionalInfo: formatDate(lastViewedDate),
      onClick: () => {
        $state.go(CONFIG.states.CATALOG, {
          filter,
          shouldSelectFirstItem: true,
        });
        sendDataExplorerLandingPageViewedObjectLinkBiAnalytics();
      },
    };
  });
};

export const mapFollowedObjectsToListItems = (followedObjects: FollowedObjectEntity[]): ListEntityItem[] => {
  return followedObjects.map(followedObject => {
    const { objectName, fullyQualifiedName, scannerTypeGroup, followerDetails } = followedObject;
    const icon = scannerTypeGroupToIconMap[scannerTypeGroup];

    const filter = mapFieldValueToBiqlString('fullyQualifiedName', fullyQualifiedName);

    return {
      name: objectName,
      key: fullyQualifiedName,
      icon,
      additionalInfo: formatDate(followerDetails?.startFollowAt),
      onClick: () => {
        $state.go(CONFIG.states.CATALOG, {
          filter,
          shouldSelectFirstItem: true,
        });
        sendDataExplorerLandingPageFollowedObjectLinkBiAnalytics();
      },
    };
  });
};

export const fetchDataSourcesByScanDate = async (params: { limit: number; skip: number }) => {
  const query = queryService.getGridConfigQuery({
    skip: params.skip,
    limit: params.limit,
    requireTotalCount: true,
    sort: [{ field: 'connectionStatusScan.last_connection', order: 'desc' }],
  });

  try {
    const { data } = await httpService.fetch<{ data: DataSourcesResponse }>(`ds-connections?${query}`);
    return data.data.ds_connections;
  } catch (e) {
    console.error('Failed to fetch DataSources', e);
    return [];
  }
};
