import React, { FC } from 'react';
import styled from '@mui/material/styles/styled';
import { BigidBody1, BigidHeading2 } from '@bigid-ui/components';
import { formatBytes, formatNumberCompact } from '../../../../utilities/numericDataConverter';
import { DuplicationSizeAndCostProps } from '../types/DataDuplicationWidgetTypes';
import { useLocalTranslation } from '../../translations';

const BYTES_IN_GB = 1024 ** 3;
const COST_PER_GB = 0.02;
const MINIMUM_SAVINGS_TO_DISPLAY = 100;

const bytesToGB = (bytes: number): number => {
  return bytes / BYTES_IN_GB;
};

const Container = styled('div')`
  display: flex;
  padding: 0 8px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
`;

const Cards = styled('div')`
  display: flex;
  padding: 8px 0;
  align-items: center;
  gap: 12px;
  flex: 1 0 0;
`;

const InfoItem = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
`;

const calculatePotentialCostSavings = (totalSizeInBytes: number, minimumSavingsNotReached: string): string => {
  const sizeInGB = bytesToGB(totalSizeInBytes);
  const costSavings = sizeInGB * COST_PER_GB;
  return costSavings < MINIMUM_SAVINGS_TO_DISPLAY ? minimumSavingsNotReached : `$${formatNumberCompact(costSavings)}`;
};

export const DataDuplicationHeader: FC<DuplicationSizeAndCostProps> = ({ latestEntry }) => {
  const { t } = useLocalTranslation();
  const data = [
    {
      value: formatNumberCompact(latestEntry?.amount),
      label: t('dataDuplicationWidget.headerData.totalNumber'),
    },
    {
      value: formatBytes(latestEntry?.volume),
      label: t('dataDuplicationWidget.headerData.totalSize'),
    },
    {
      value: calculatePotentialCostSavings(
        latestEntry?.volume,
        t('dataDuplicationWidget.headerData.minimumSavingsNotReached'),
      ),
      label: t('dataDuplicationWidget.headerData.potentialCostSavings'),
    },
  ];

  return (
    <Container>
      {data.map(({ value, label }, index) => (
        <Cards key={index}>
          <InfoItem>
            <BigidHeading2>{value}</BigidHeading2>
            <BigidBody1>{label}</BigidBody1>
          </InfoItem>
        </Cards>
      ))}
    </Container>
  );
};
