import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { closeSystemDialog, SystemDialogContentProps } from '../../../../../../../services/systemDialogService';
import {
  BigidForm,
  BigidFormValidateOnTypes,
  BigidFormField,
  BigidFormProps,
  BigidFormStateAndHandlers,
  BigidLoader,
  BigidDropdownOption,
} from '@bigid-ui/components';
import { ModalContentWrapper, ModalContentType, ApplyActionOnCase } from '../CaseActionsModal';
import { useLocalTranslation } from '../../../../../translations';
import { CaseModel } from '../../../../../actionableInsightsService';
import { ActionsListDataProps, CaseSidePanelReducerAction } from '../../../../hooks/CaseSidePanelReducer';
import { notificationService } from '../../../../../../../services/notificationService';
import { CreateAssessmentEvents, createAssessmentEventsEmitter } from './createAIAssessmentEvent';
import { getCurrentUser, User, fetchSystemUsersWithFullName } from '../../../../../../../utilities/systemUsersUtils';
import { customAppService } from '../../../../../../../services/customAppService';
import {
  fetchAssetsOptions,
  updateApplicationsTargetSourceList,
} from '../../../../../../../utilities/applicationsUtils';
import { Link } from '@mui/material';
import {
  generateCreateAIAssessmentFormFields,
  createAssessmentOwnerObjectForPayload,
  createAssetsArrayForPayload,
} from './utils';

interface FormInitValues {
  assessmentName: string;
  assessmentTemplate: string;
  assessmentOwner: BigidDropdownOption[];
}
export const CreateAIAssessmentModalContent: FC<SystemDialogContentProps<ModalContentType>> = ({ type }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [formFields, setFormFields] = useState<BigidFormField[]>([]);
  const [formInitValues, setFormInitValues] = useState<FormInitValues>({
    assessmentName: '',
    assessmentTemplate: '',
    assessmentOwner: [],
  });
  const createAIAssessmentFormControls = useRef<BigidFormStateAndHandlers>();
  const { t } = useLocalTranslation('Action.createAssessment');

  const getInitValues = useCallback(
    (currentLoggedInUser: Partial<User>, systemUsers: BigidDropdownOption[]) => {
      let assessmentOwner;
      if (currentLoggedInUser && systemUsers.length > 0) {
        assessmentOwner = systemUsers.find(user => user.value === currentLoggedInUser.id);
      }
      return {
        assessmentName: '',
        assessmentTemplate: t('form.systemTemplate'),
        assessmentOwner: assessmentOwner ? [assessmentOwner] : [],
      };
    },
    [t],
  );

  useEffect(() => {
    const fetchPopupData = async () => {
      setIsLoading(true);
      try {
        const [currentLoggedInUser, systemUsersOptions, assetsOptions] = await Promise.all([
          await getCurrentUser(),
          await fetchSystemUsersWithFullName(),
          await fetchAssetsOptions(),
        ]);
        setFormFields(generateCreateAIAssessmentFormFields(systemUsersOptions, assetsOptions));
        setFormInitValues(getInitValues(currentLoggedInUser, systemUsersOptions));
      } catch (e) {
        notificationService.error(t('message.failedRetrievingTheData'), {
          duration: 3000,
        });
      } finally {
        setIsLoading(false);
      }
    };

    fetchPopupData();
  }, []);

  const handleSubmitCreateAssessmentForm = useCallback(
    async (event: {
      caseSidePanelData: CaseModel;
      actionData: ActionsListDataProps;
      dispatch: React.Dispatch<CaseSidePanelReducerAction>;
    }) => {
      try {
        await createAIAssessmentFormControls?.current.validateAndSubmit(async formValues => {
          setIsLoading(true);
          try {
            const { caseSidePanelData, actionData, dispatch } = event;
            const { subType, command, actionName } = actionData;

            await updateApplicationsTargetSourceList(caseSidePanelData.dataSourceName, formValues['assets']);

            const payload = {
              type: 'PIA',
              subType: subType,
              additionalProperties: {
                configurationId: caseSidePanelData.id,
                actionName: actionName,
                command: command,
                dsName: caseSidePanelData.dataSourceName,
                policyName: caseSidePanelData.policyName,
                assessmentName: formValues['assessmentName'],
                assessmentOwner: createAssessmentOwnerObjectForPayload(formValues['assessmentOwner'][0]),
                assetIds: createAssetsArrayForPayload(formValues['assets']),
              },
            };

            const successMessage = t('message.successMessage');
            const contentElement = (
              <Link
                data-aid="privacy-app-page"
                onClick={async () => {
                  try {
                    await customAppService.redirectToCustomApp({ appName: 'PIA', appRoute: '' });
                  } catch (e) {
                    notificationService.error(t('message.failedRedirectToCustomApp'), { duration: 3000 });
                  }
                }}
              >
                {`View assessment`}
              </Link>
            );
            await ApplyActionOnCase(
              payload,
              dispatch,
              caseSidePanelData.id,
              successMessage,
              command,
              false,
              contentElement,
            );
          } catch (e) {
            notificationService.error(t('message.failedToAttachDSToAsset'), { duration: 3000 });
          } finally {
            setIsLoading(false);
            closeSystemDialog();
          }
        });
      } catch (err) {
        notificationService.error('An error has occurred');
      }
    },
    [t],
  );

  useEffect(() => {
    createAssessmentEventsEmitter?.addEventListener(
      CreateAssessmentEvents.CREATE_AI_ASSESSMENT,
      handleSubmitCreateAssessmentForm,
    );

    return () => {
      createAIAssessmentFormControls?.current.clear();
      createAssessmentEventsEmitter?.removeEventListener(
        CreateAssessmentEvents.CREATE_AI_ASSESSMENT,
        handleSubmitCreateAssessmentForm,
      );
    };
  }, []);

  const createAIAssessmentFormProps: BigidFormProps = useMemo(
    () => ({
      controlButtons: false,
      validateOn: BigidFormValidateOnTypes.SUBMIT,
      stateAndHandlersRef: createAIAssessmentFormControls,
      initialValues: formInitValues,
      displayFormLevelError: true,
      fields: formFields,
    }),
    [createAIAssessmentFormControls, formInitValues, formFields],
  );

  return (
    <ModalContentWrapper>
      {isLoading ? <BigidLoader /> : <BigidForm {...createAIAssessmentFormProps} />}
    </ModalContentWrapper>
  );
};

export const createAIAssessmentActionUpdate = async (
  caseSidePanelData: CaseModel,
  actionData: ActionsListDataProps,
  dispatch: React.Dispatch<CaseSidePanelReducerAction>,
) => {
  createAssessmentEventsEmitter.emit(CreateAssessmentEvents.CREATE_AI_ASSESSMENT, {
    caseSidePanelData,
    actionData,
    dispatch,
  });
};
