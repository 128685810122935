import { applicationSetupService } from '../../administration/applicationSetup/applicationSetup.service';
import { ApplicationEntity } from '../views/ApplicationSetup/types';
import { createAsset } from '../views/LegalEntities/LegalEntitiesService';
import { BigidDropdownOption } from '@bigid-ui/components';

export const fetchAssetsOptions = async (): Promise<BigidDropdownOption[]> => {
  try {
    const { applications } = await applicationSetupService.getApplicationItems();
    return applications.map((application: ApplicationEntity) => {
      return {
        displayValue: application.name,
        value: application._id,
        id: application._id,
      };
    });
  } catch (err) {
    console.error(`Failed to fetch Assets: ${JSON.stringify(err?.response)}`);
    throw new Error('Failed to fetch Assets');
  }
};

export const createNewAsset = async (value: string): Promise<BigidDropdownOption> => {
  try {
    const { _id } = await createAsset({ name: value });
    return {
      id: _id,
      value: _id,
      displayValue: value,
      isNew: true,
    };
  } catch (err) {
    console.error(`Failed to create Asset: ${JSON.stringify(err?.response)}`);
    throw new Error('Failed to create Asset');
  }
};

export const updateApplicationsTargetSourceList = async (
  dataSource: string,
  selectedApplications: Partial<BigidDropdownOption>[],
): Promise<void> => {
  try {
    const targetSourceList = {
      target_source_list: [{ source: dataSource }],
    };
    const requests = selectedApplications.map(application => {
      return applicationSetupService.updateApplicationTargetSourceList(application.displayValue, targetSourceList);
    });
    await Promise.all(requests);
  } catch (err) {
    console.error(`Failed to update Asset: ${JSON.stringify(err?.response)}`);
    throw new Error('Failed to update Asset');
  }
};
