import {
  BigidDropdownNewOptionValidation,
  BigidDropdownOption,
  BigidFormField,
  BigidFormFieldTypes,
} from '@bigid-ui/components';
import { notificationService } from '../../../../../../../services/notificationService';
import { isNameValid } from '../../../../../../../utilities/validation';
import { createNewAsset } from '../../../../../../../utilities/applicationsUtils';
import { getFixedT } from '../../../../../translations';

const tErrorMessages = getFixedT('Action.createAssessment.message');
const tForm = getFixedT('Action.createAssessment.form');

const isNewAssetValid = (value: string): Promise<BigidDropdownNewOptionValidation> => {
  const trimmedValue = value.trim();
  const isInValidValue = !trimmedValue || /[!@#$%^&*()\\=+]/gi.test(trimmedValue);
  if (isInValidValue) {
    notificationService.error(tErrorMessages('failedCreateNewAsset'));
  }
  return Promise.resolve({
    isValid: !isInValidValue,
    message: tErrorMessages('invalidAsset'),
  });
};

export const generateCreateAIAssessmentFormFields = (
  systemUsers: BigidDropdownOption[],
  assets: BigidDropdownOption[],
): BigidFormField[] => {
  return [
    {
      label: tForm('assessmentName') as string,
      name: 'assessmentName',
      type: BigidFormFieldTypes.TEXT,
      isRequired: true,
      validate: (name: string) => {
        if (name.length === 0) {
          return tErrorMessages('mandatoryField') as string;
        } else if (!isNameValid(name)) {
          return tErrorMessages('invalidName') as string;
        }
        return false;
      },
    },
    {
      label: tForm('assessmentTemplate') as string,
      name: 'assessmentTemplate',
      type: BigidFormFieldTypes.TEXT,
      isRequired: true,
      disabled: true,
      fieldProps: { readOnly: true },
    },
    {
      label: tForm('assessmentOwner') as string,
      name: 'assessmentOwner',
      type: BigidFormFieldTypes.DROP_DOWN,
      isRequired: true,
      dropDownOptions: systemUsers,
      validate: (selectedOption: BigidDropdownOption[]) => {
        if (!selectedOption?.length || !selectedOption[0]?.value) {
          return tErrorMessages('mandatoryField') as string;
        }
        return false;
      },
    },
    {
      label: tForm('assets') as string,
      name: 'assets',
      type: BigidFormFieldTypes.DROP_DOWN,
      fieldProps: {
        isMulti: true,
        isCreatable: true,
        isSearchable: true,
        applyOnChange: true,
        displayLimit: 20,
        createOptionText: tForm('selectAssetsField.createOption'),
        noOptionsMessage: tForm('selectAssetsField.noOptions'),
        noSearchResultsMessage: tForm('selectAssetsField.noSearchResults'),
        placeholder: tForm('selectAssetsField.assetsPlaceholder'),
        searchBarPlaceholder: tForm('selectAssetsField.searchBarPalceholder'),
        isAsync: true,
        getIsNewOptionValid: isNewAssetValid,
        onCreate: async (value: string) => {
          try {
            const newAssetOption: BigidDropdownOption = await createNewAsset(value);
            return newAssetOption;
          } catch (e) {
            notificationService.error(tErrorMessages('failedToCreateAsset'), { duration: 3000 });
          }
        },
      },
      isRequired: true,
      dropDownOptions: assets,
      validate: (selectedOption: BigidDropdownOption[]) => {
        if (!selectedOption?.length) {
          return tErrorMessages('mandatoryField') as string;
        } else {
          selectedOption.forEach(option => {
            if (!option.value || !option.displayValue) return tErrorMessages('invalidAsset') as string;
          });
        }
        return false;
      },
    },
  ];
};

export const createAssessmentOwnerObjectForPayload = (selectedAssessmentOwner: BigidDropdownOption) => {
  return {
    email: selectedAssessmentOwner.value,
    name: selectedAssessmentOwner.displayValue,
  };
};

export const createAssetsArrayForPayload = (selectedAssets: BigidDropdownOption[]) => {
  return selectedAssets.map((asset: BigidDropdownOption) => {
    return asset.id;
  });
};
