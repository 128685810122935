import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { debounce, partition } from 'lodash';
import { Stack } from '@mui/material';
import { Generic2, iconSets } from '@bigid-ui/icons';
import {
  BigidCheckbox,
  BigidDialog,
  BigidSearch,
  EntityEvents,
  entityEventsEmitter,
  PrimaryButton,
  TertiaryButton,
} from '@bigid-ui/components';

import { notificationService } from '../../../services/notificationService';
import { fetchRegulationsData, updateRegulationStatus } from '../RegulationServices';
import { ComplianceRegulation } from '../types';
import { REGULATIONS_NAMESPACE } from '../translations';
import { regulationsEventEmitter, RegulationsEvents } from './regulationsEventEmitter';

enum LoadingStatus {
  DEFAULT = 'DEFAULT',
  ERROR = 'ERROR',
  LOADING = 'LOADING',
  SUCCESS = 'SUCCESS',
}
const LIMIT = 100;

export function AddRegulationsModal(props: { isOpen: boolean; onClose: () => void }) {
  const { onClose, isOpen } = props;
  const { t } = useTranslation(REGULATIONS_NAMESPACE);
  const loadMoreRef = useRef<HTMLDivElement>();
  const [searchInput, setSearchInput] = useState<string>('');
  const [regulations, setRegulations] = useState<ComplianceRegulation[]>([]);
  const [loadingStatus, setLoadingStatus] = useState(LoadingStatus.DEFAULT);
  const [totalCount, setTotalCount] = useState(0);
  const [selectedList, setSelectedList] = useState<string[]>([]);
  const [allSelectedChecked, setSelectAll] = useState<boolean | null>(null);

  const handleOnSearch = useCallback((searchText: string): void => {
    setSearchInput(searchText);
    setSelectAll(null);
  }, []);

  const handleCheckRegulation = useCallback((regulation: ComplianceRegulation, isChecked: boolean) => {
    setSelectedList(selectedList =>
      isChecked ? selectedList.concat(regulation._id) : selectedList.filter(item => item !== regulation._id),
    );
    setSelectAll(null);
  }, []);

  const handleSelectAll = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSelectAll(e.target.checked);
      setSelectedList(e.target.checked ? regulations.map(item => item._id) : []);
    },
    [regulations],
  );

  const handleFetchRegulations = useCallback(
    async (skip = 0) => {
      try {
        setLoadingStatus(LoadingStatus.LOADING);
        const { data, totalCount } = await fetchRegulationsData({
          limit: LIMIT,
          skip,
          requireTotalCount: true,
          sort: [{ field: 'name', order: 'asc' }],
          filter: [
            {
              field: 'enabled',
              operator: 'equal',
              value: false,
            },
            {
              field: 'name',
              operator: 'textSearch',
              value: searchInput,
            },
            {
              field: 'region',
              operator: 'textSearch',
              value: searchInput,
            },
            {
              field: 'countries',
              operator: 'textSearch',
              value: searchInput,
            },
            {
              field: 'states',
              operator: 'textSearch',
              value: searchInput,
            },
          ],
        });
        setRegulations(regs => (skip ? regs.concat(data) : data));
        setTotalCount(totalCount);
        setLoadingStatus(LoadingStatus.SUCCESS);
      } catch (err) {
        console.error(err);
        setLoadingStatus(LoadingStatus.ERROR);
        onClose();
        notificationService.error(t('Error.requests.fetchRegulations'));
      }
    },
    [t, onClose, searchInput],
  );

  const handleSubmit = useCallback(async () => {
    try {
      setLoadingStatus(LoadingStatus.LOADING);
      if (allSelectedChecked !== null) {
        await updateRegulationStatus(
          {
            filter: [
              {
                field: 'name',
                operator: 'textSearch',
                value: searchInput.trim(),
              },
            ],
          },
          allSelectedChecked,
        );
      } else {
        const [enableList, disableList] = partition(
          regulations.filter(item => item.enabled !== selectedList.includes(item._id)),
          item => !item.enabled,
        ).map(regulations => regulations.map(item => item._id));

        if (enableList.length) {
          await updateRegulationStatus(
            {
              filter: [
                {
                  field: '_id',
                  operator: 'in',
                  value: enableList,
                },
              ],
            },
            true,
          );
        }
        if (disableList.length) {
          await updateRegulationStatus(
            {
              filter: [
                {
                  field: '_id',
                  operator: 'in',
                  value: disableList,
                },
              ],
            },
            false,
          );
        }
      }
      entityEventsEmitter.emit(EntityEvents.RELOAD);
      regulationsEventEmitter.emit(RegulationsEvents.REGULATIONS_ADDED);
      setLoadingStatus(LoadingStatus.SUCCESS);
      onClose();
      notificationService.success(t('Success.requests.addRegulations'));
    } catch (err) {
      console.error(err);
      setLoadingStatus(LoadingStatus.ERROR);
      notificationService.error(t('Error.requests.addRegulations'));
    }
  }, [t, onClose, allSelectedChecked, searchInput, regulations, selectedList]);

  useEffect(() => {
    handleFetchRegulations();
  }, [handleFetchRegulations]);

  useEffect(() => {
    setSelectedList(selectedList =>
      (allSelectedChecked === null
        ? regulations.filter(item =>
            item.enabled === selectedList.includes(item._id) ? item.enabled : selectedList.includes(item._id),
          )
        : allSelectedChecked
        ? regulations
        : []
      ).map(item => item._id),
    );
  }, [regulations, allSelectedChecked]);

  useEffect(() => {
    const options = {
      rootMargin: '0px',
      threshold: 0.9,
    };
    if (loadingStatus === LoadingStatus.SUCCESS && loadMoreRef.current) {
      const observer = new IntersectionObserver(entries => {
        const entry = entries[0];
        if (entry.isIntersecting) {
          const skip = regulations.length;
          if (skip < totalCount) {
            handleFetchRegulations(skip);
          }
        }
      }, options);
      observer.observe(loadMoreRef.current);
      return () => observer.disconnect();
    }
  }, [handleFetchRegulations, loadingStatus, regulations, totalCount]);

  const selectedAllIndicate = useMemo(() => {
    const allLoaded = totalCount === regulations.length;
    const allSelected = selectedList.length === regulations.length;
    const allLoadedAndAllSelected = allLoaded && allSelected;
    const someSelected = !allLoaded || (!allSelected && selectedList.length > 0);
    const selectedAllIndicate =
      allSelectedChecked !== null ? allSelectedChecked : allLoadedAndAllSelected || (someSelected && null);

    return selectedAllIndicate;
  }, [allSelectedChecked, regulations.length, selectedList.length, totalCount]);

  const stateChanged = useMemo(
    () => regulations.some(item => item.enabled !== selectedList.includes(item._id)),
    [regulations, selectedList],
  );

  return (
    <BigidDialog
      title={t('Titles.addRegulations')}
      maxWidth="xs"
      borderTop
      borderBottom
      fixedHeight
      isOpen={isOpen}
      isLoading={loadingStatus === LoadingStatus.LOADING}
      onClose={onClose}
      buttons={[
        {
          text: t('Labels.cancel'),
          component: TertiaryButton,
          onClick: onClose,
        },
        {
          text: t('Labels.add'),
          component: PrimaryButton,
          onClick: handleSubmit,
          disabled: !stateChanged,
        },
      ]}
    >
      <Stack pb="20px">
        <BigidSearch
          placeholder={t('Labels.search')}
          value={searchInput}
          onChange={debounce(handleOnSearch, 600)}
          onSubmit={handleOnSearch}
        />
      </Stack>
      <Stack overflow="auto" mb={-4}>
        {!!regulations.length && (
          <Stack px={1.5} py={1}>
            <BigidCheckbox
              label={t('Labels.selectAll')}
              checked={selectedAllIndicate}
              indeterminate={selectedAllIndicate === null}
              onChange={handleSelectAll}
            />
          </Stack>
        )}
        {regulations.map(regulation => (
          <Stack key={regulation._id} px={1.5} py={1}>
            <BigidCheckbox
              checked={selectedList.includes(regulation._id)}
              onChange={e => handleCheckRegulation(regulation, e.target.checked)}
              label={`${regulation.name} (${regulation.states.length > 0 ? `${regulation.states.join(', ')} - ` : ''}${
                regulation.region
              })`}
              icon={() => {
                const Icon = iconSets.FlagIcons?.[regulation.flagCode] || Generic2;
                return <Icon width={20} height={20} style={{ margin: '0 4px', flex: 'none' }} />;
              }}
            />
          </Stack>
        ))}
        <div ref={loadMoreRef}></div>
      </Stack>
    </BigidDialog>
  );
}
